import * as React from 'react';
import Box from '@mui/material/Box';
import {DataGrid, GridColDef} from '@mui/x-data-grid';
import {Backdrop, Button, CircularProgress, MenuItem, Select, TextField} from '@mui/material';

import OptionComponent from './components/OptionComponent';
import PopupComponent from './components/PopupComponent';
import {curl, API_BASE_URL} from '../util/RestApi';
import {CurlResponse} from '../util/type/CurlResponse';
import AlertDialog from '../util/AlertDialog';
import {useState} from 'react';
import {useRecoilState} from 'recoil';
import {loginState} from '../atom/LoginState';
import {LoginStateType} from '../atom/type/LoginStateType';
import CountComponent from './components/CountComponent';

const HomeScreen = () => {
  const [slotData, setSlotData] = React.useState([]);
  const [selectedFilter, setSelectedFilter] = React.useState('mid');
  const [openPopup, setOpenPopup] = React.useState(false);
  const [confirm, setConfirm] = React.useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [slotConfirmation, setSlotConfirmation] = React.useState({
    title: '',
    contents: '',
  });

  const [loginInfo, setLoginInfo] = useRecoilState<LoginStateType>(loginState);
  const [loading, setLoading] = useState(false);
  const [gridHeight, setGridHeight] = useState(400); // 기본 높이 설정

  async function getProductList() {
    let url = API_BASE_URL + '/product/list';
    if (loginInfo.roleName === 'ROLE_ADMIN') {
      url = API_BASE_URL + '/product/all-list';
    }

    const productListResponse: CurlResponse = await curl<CurlResponse>(url, 'GET', null);
    return productListResponse.data;
  }

  React.useEffect(() => {
    (async () => {
      // useState(localStorage.getItem(GLOBAL_CUSTOM_TOKEN));
      const data = await getProductList();
      setSlotData(data);
    })();
  }, []);

  const columns: GridColDef[] = [
    {field: 'id', headerName: 'ID', width: 90, disableColumnMenu: true, hide: true},
    {
      field: 'userVO',
      headerName: '고객ID',
      width: 150,
      editable: false,
      disableColumnMenu: true,
      hide: loginInfo.roleName === 'ROLE_USER',
      valueGetter: (params) => params.value.email,
    },
    {
      field: 'userVOname',
      headerName: '고객 이름',
      width: 150,
      editable: false,
      disableColumnMenu: true,
      hide: loginInfo.roleName === 'ROLE_USER',
      valueGetter: (params) => params.row.userVO.username,
    },
    {
      field: 'rowId',
      headerName: 'rowId',
      width: 150,
      hide: true,
      disableColumnMenu: true,
    },
    {
      field: 'slot',
      headerName: '슬롯',
      width: 150,
      hide: true,
      disableColumnMenu: true,
    },
    {
      field: 'mid',
      headerName: 'mid',
      width: 150,
      editable: true,
      disableColumnMenu: true,
    },
    {
      field: 'productName',
      headerName: '상품명',
      width: 150,
      editable: true,
      disableColumnMenu: true,
    },
    {
      field: 'startDate',
      headerName: '시작기간',
      width: 150,
      editable: loginInfo.roleName === 'ROLE_ADMIN',
      disableColumnMenu: true,
    },
    {
      field: 'endDate',
      headerName: '종료기간',
      width: 150,
      editable: loginInfo.roleName === 'ROLE_ADMIN',
      disableColumnMenu: true,
      renderCell: (param) => {
        const targetDate = param.value; // endDate 값 가져오기
        const currentDate = new Date().toISOString().split('T')[0];
        const timeDiff = new Date(targetDate) - new Date(currentDate);
        const diffDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
        let color = 'black';

        if (diffDays <= 3 && diffDays >= 0) {
          color = '#ff3d00'; // 3일 이내면 노란색으로 변경
        } else if (diffDays < 3) {
          color = 'red'; // 3일 초과면 빨간색으로 변경
        }

        return <div style={{color: color}}>{targetDate}</div>; // 해당 부분 수정
      },
    },
    {
      field: 'getDate',
      headerName: '가져온시간',
      width: 150,
      editable: false,
      disableColumnMenu: true,
      hide: true,
    },

    {
      field: 'actions',
      headerName: '설정',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return <OptionComponent onEdit={handleEditAction} onDelete={handleDeleteAction} isAdmin={loginInfo.roleName} />;
      },
    },
  ];

  const handleEditAction = () => {
    setSlotConfirmation({
      title: '슬롯 수정',
      contents: '슬롯을 수정하시겠습니까?',
    });
    setConfirm(true);
  };

  const handleDeleteAction = () => {
    setSlotConfirmation({
      title: '슬롯 삭제',
      contents: '슬롯을 삭제하시겠습니까?',
    });
    setConfirm(true);
  };

  const deleteProduct = async () => {
    const id = selectedRowData.id;
    const deleteResponse: CurlResponse = await curl<CurlResponse>(API_BASE_URL + '/product/' + id, 'DELETE', null);

    if (deleteResponse.status !== 'success') {
      alert('삭제에 실패하였습니다.');
      return false;
    }

    const filteredItems = slotData.filter((item) => item.id !== id);
    setSlotData(filteredItems);
    setConfirm(false);
    alert('삭제되었습니다.');
  };

  const updateProduct = async () => {
    const id = selectedRowData.id;
    const data = selectedRowData.row;
    console.log('data', data);

    try {
      data.updateMid = data.mid;
      const midResponse: CurlResponse = await curl<CurlResponse>(API_BASE_URL + '/link/mid', 'PUT', data);
      const updateResponse: CurlResponse = await curl<CurlResponse>(API_BASE_URL + '/product', 'PUT', data);

      if (midResponse.status !== 'success' && updateResponse.status !== 'success') {
        alert('수정에 실패하였습니다.');
        return false;
      }

      alert('수정되었습니다.');
    } catch (e) {
      alert('수정도중에 에러가 발생하였습니다.');
    }

    setConfirm(false);
  };

  React.useEffect(() => {
    const calculateGridHeight = () => {
      if (slotData.length > 0) {
        const rowHeight = 37; // 데이터 행의 높이
        const headerHeight = 37; // 헤더 높이
        const paginationHeight = 52; // 페이지네이션 높이
        const minHeight = 200; // 최소 높이 설정

        let dataHeight = slotData.length * rowHeight;

        if (slotData.length >= 100) {
          dataHeight = 100 * rowHeight + 3;
        }

        const calculatedHeight = dataHeight + headerHeight + paginationHeight;
        setGridHeight(Math.max(calculatedHeight, minHeight));
      }
    };
    calculateGridHeight();
  }, [slotData]);

  return slotData.length !== undefined ? (
    <>
      <AlertDialog
        title={slotConfirmation.title}
        contents={slotConfirmation.contents}
        open={confirm}
        setOpen={setConfirm}
        action={() => (slotConfirmation.title === '슬롯 삭제' ? deleteProduct() : updateProduct())}
      />

      {loginInfo.roleName === 'ROLE_ADMIN' && (
        <div style={{textAlign: 'left'}}>
          <Button
            variant="contained"
            color="primary"
            onClick={async () => {
              if (!window.confirm('동기화하시겠습니까?\n3분정도 소요됩니다.')) {
                return;
              }
              setLoading(true);
              const response: CurlResponse = await curl<CurlResponse>(API_BASE_URL + '/link/get', 'GET', null);
              if (response.status === 'success') {
                alert('동기화하였습니다.');
              } else {
                alert('동기화실패했습니다.');
              }
              setLoading(false);
            }}>
            동기화
          </Button>
          <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
      <NoticeComponent loginInfo={loginInfo} />
      <Box sx={{height: '100%', width: '100%'}}>
        {loginInfo.roleName === 'ROLE_ADMIN' ?? (
          <div
            style={{
              display: 'flex',
              // flexDirection: 'column',
              flexWrap: 'wrap',
              alignContent: 'flex-start',
            }}>
            <Select
              value={selectedFilter}
              onChange={(e) => setSelectedFilter(e.target.value)}
              displayEmpty
              inputProps={{'aria-label': 'Without label'}}
              sx={{marginBottom: '16px', width: '120px'}}>
              <MenuItem value={'id'}>고객ID</MenuItem>
              <MenuItem value={'mid'}>MID</MenuItem>
            </Select>
            <TextField id="standard-basic" label="입력" variant="standard" style={{marginLeft: '16px'}} />
            <Button
              variant="contained"
              style={{marginLeft: '16px', height: '60px'}}
              onClick={() => {
                setOpenPopup(true);
              }}>
              상품등록
            </Button>
          </div>
        )}
        <PopupComponent open={openPopup} setOpen={setOpenPopup} />
        <CountComponent />
        <DataGrid
          onCellClick={(data) => {
            setSelectedRowData(data);
          }}
          initialState={{
            sorting: {
              sortModel: [{field: 'userVOname', sort: 'asc'}],
            },
          }}
          onEditCellPropsChange={(params, event) => {
            const {id, field, props} = params;
            setSlotData((prevData) => {
              const newData = [...prevData];
              const rowIndex = newData.findIndex((row) => row.id === id);
              newData[rowIndex] = {
                ...newData[rowIndex],
                [field]: props.value,
              };
              return newData;
            });
          }}
          rows={slotData}
          columns={columns}
          pageSize={100}
          rowsPerPageOptions={[5]}
          checkboxSelection
          disableSelectionOnClick
          experimentalFeatures={{newEditingApi: true}}
          style={{height: gridHeight}} // 높이 설정
          rowHeight={37}
          headerHeight={37}
        />
      </Box>
    </>
  ) : null;
};

const NoticeComponent: React.FC = (props) => {
  const {loginInfo} = props;
  const [notice, setNotice] = useState('');

  async function getNotice() {
    let url = API_BASE_URL + '/notice';

    const productListResponse: CurlResponse = await curl<CurlResponse>(url, 'GET', null);
    return productListResponse.data.contents;
  }

  async function putNotice() {
    let url = API_BASE_URL + '/notice';

    const productListResponse: CurlResponse = await curl<CurlResponse>(url, 'PUT', {
      contents: notice,
    });
    if (productListResponse.status == 'success') {
      alert('저장되었습니다.');
    } else {
      alert('저장에 실패하였습니다.');
    }
  }

  React.useEffect(() => {
    (async () => {
      const noticeData = await getNotice();
      setNotice(noticeData);
    })();
  }, []);

  return (
    <div
      style={{
        padding: '4px',
        borderRadius: '10px',
        backgroundColor: '#f5f5f5',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Optional: Add a subtle box shadow
      }}>
      <p>
        <b>공지사항</b>
      </p>

      {loginInfo.roleName === 'ROLE_ADMIN' ? (
        <Box display="flex" alignItems="center">
          <TextField
            id="filled-multiline-flexible"
            hiddenLabel
            multiline
            maxRows={4}
            variant="filled"
            value={notice}
            onChange={(e) => {
              setNotice(e.target.value);
            }}
            fullWidth
          />
          <Button
            variant="contained"
            style={{marginLeft: '20px', height: '60px', minWidth: '100px'}}
            onClick={async () => {
              await putNotice();
            }}>
            {'공지\n수정'}
          </Button>
        </Box>
      ) : (
        <div dangerouslySetInnerHTML={{__html: notice.replace(/\n/g, '<br/>')}} />
      )}
    </div>
  );
};

export default HomeScreen;
