import React, {useEffect, useRef, useState} from 'react';
import {TextField, Typography, makeStyles, createStyles, Grid} from '@material-ui/core';
import {Button} from '@mui/material';
import {CurlResponse} from '../util/type/CurlResponse';
import {curl} from '../util/RestApi';
import {API_BASE_URL} from '../util/RestApi';

const SettingScreen = () => {
  const [data, setData] = useState(null);
  useEffect(() => {
    (async () => {
      const userData: CurlResponse = await curl<CurlResponse>(API_BASE_URL + '/user/me', 'GET', null);
      console.log('userData.data', userData.data);
      setData(userData.data);
    })();
  }, []);

  const update = async () => {
    if (!('password' in data)) {
      data.password = '';
    }
    const userData: CurlResponse = await curl<CurlResponse>(API_BASE_URL + '/user/update', 'PUT', data);
    alert('수정하였습니다.');
    // console.log('userData.data', userData.data);
  };

  if (data === null) return null;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField label="이메일" fullWidth disabled value={data.email} />
      </Grid>
      <Grid item xs={12}>
        <TextField label="이름" fullWidth value={data.username} onChange={(e) => setData({...data, username: e.target.value})} />
      </Grid>
      {/* <Grid item xs={12}>
        <TextField label="휴대폰번호" fullWidth value={data.username}/>
      </Grid> */}
      <Grid item xs={12}>
        <TextField
          label="비밀번호"
          fullWidth
          value={data.password ?? ''}
          onChange={(e) => setData({...data, password: e.target.value})}
          type="password"
        />
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" onClick={update}>
          수정하기
        </Button>
      </Grid>
    </Grid>
  );
};

export default SettingScreen;
