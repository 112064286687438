import React, {useEffect, useRef, useState} from 'react';
import {TextField, Typography, makeStyles, createStyles, Grid} from '@material-ui/core';
import {Button} from '@mui/material';
import {CurlResponse} from '../util/type/CurlResponse';
import {curl} from '../util/RestApi';
import {API_BASE_URL} from '../util/RestApi';

const SyncScreen = () => {
  const [data, setData] = useState(null);
  // useEffect(() => {
  //   (async () => {
  //     const userData: CurlResponse = await curl<CurlResponse>(API_BASE_URL + '/link/get', 'GET', null);
  //     console.log('userData', userData);
  //   })();
  // }, []);

  const sync = async () => {
    const list: CurlResponse = await curl<CurlResponse>(API_BASE_URL + '/link/sync', 'POST', null);
    console.log(list);
    return list;
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Button
          onClick={async () => {
            await sync();
          }}>
          동기화
        </Button>
      </Grid>
    </Grid>
  );
};

export default SyncScreen;
