import './App.css';
import NavigationBar from './navigate/NavigationBar';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {RecoilRoot, atom, selector, useRecoilState, useRecoilValue} from 'recoil';

function App() {
  return (
    <div className="App">
      <RecoilRoot>
        <Router>
          <NavigationBar />
        </Router>
      </RecoilRoot>
    </div>
  );
}

export default App;
