import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {TextField, Button, Paper, Typography} from '@material-ui/core';
import {curl, API_BASE_URL} from '../util/RestApi';
import {LoginRequest} from './type/LoginRequest';
import {CurlResponse} from '../util/type/CurlResponse';
import {GLOBAL_CUSTOM_TOKEN} from '../util/util';
import {useRecoilState} from 'recoil';
import {LoginStateType} from '../atom/type/LoginStateType';
import {loginState} from '../atom/LoginState';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
    maxWidth: 400,
    margin: 'auto',
    marginTop: theme.spacing(5),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));

const LoginScreen = () => {
  const classes = useStyles();
  const [loginInfo, setLoginInfo] = useRecoilState<LoginStateType>(loginState);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    const loginRequest: LoginRequest = {
      email: email,
      password: password,
    };

    const data: CurlResponse = await curl<CurlResponse>(API_BASE_URL + '/user/login', 'POST', loginRequest);
    const token: string = data.data.token;

    if (!!!token) {
      alert('이메일이나 비밀번호가 틀렸습니다.');
      return false;
    }

    localStorage.setItem(GLOBAL_CUSTOM_TOKEN, token);
    window.location.reload();
  };

  return (
    <Paper className={classes.paper}>
      <Typography variant="h5" component="h1">
        크리부스트
      </Typography>
      <form className={classes.form} onSubmit={handleSubmit}>
        <TextField
          label="아이디"
          // type="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          required
        />
        <TextField label="Password" type="password" value={password} onChange={(event) => setPassword(event.target.value)} required />
        <Button variant="contained" color="primary" type="submit">
          로그인
        </Button>
      </form>
    </Paper>
  );
};

export default LoginScreen;
