import {Button, Dialog, DialogTitle, List, TextField} from '@mui/material';
import Box from '@mui/material/Box';
import * as React from 'react';
import {PopupComponentProps} from '../type/PopupComponentProps';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import {curl, API_BASE_URL} from '../../util/RestApi';
import {dateFormatter} from '../../util/util';
import {CurlResponse} from '../../util/type/CurlResponse';

const PopupComponent = (props: PopupComponentProps) => {
  const {setOpen, open} = props;
  const [workingDate, setWorkingDate] = React.useState<Date>(new Date());
  const [mid, setMid] = React.useState<string>('');
  const [slot, setSlot] = React.useState<number>(0);

  const handleListItemClick = () => {
    setOpen(false);
  };

  const insertProduct = async () => {
    const startDate = dateFormatter(workingDate[0]);
    const endDate = dateFormatter(workingDate[1]);
    const regex = /^(?!0)\d{1,8}[1-9]$|^(?!0)\d{9,11}[1-9]$/;

    if (!regex.test(mid)) {
      alert('유효한 mid를 입력해주세요.');
      return false;
    }

    if (slot <= 0) {
      alert('slot은 0보다 크게 입력해주세요.');
      return false;
    }

    try {
      const data: CurlResponse = await curl<CurlResponse>(API_BASE_URL + '/product', 'POST', {
        mid: mid,
        slot: slot,
        startDate: startDate,
        endDate: endDate,
      });

      if (data.status === 'success') {
        alert('상품을 등록했습니다.');
      } else {
        alert('상품등록에 실패했습니다.');
      }
    } catch (error) {
      if (error.response?.status === 409) {
        alert('이미 등록한 mid값입니다.');
      } else {
        alert('상품등록에 실패했습니다.');
      }
    }
  };

  return (
    <Dialog
      onClose={handleListItemClick}
      open={open}
      maxWidth={'sm'}
      fullWidth={true}
      PaperProps={{
        style: {
          position: 'absolute',
          top: '50px',
        },
      }}>
      <DialogTitle style={{alignSelf: 'center'}}>상품등록</DialogTitle>
      <Box sx={{'& > :not(style)': {m: 1}}}>
        <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
          <AccountCircle sx={{color: 'action.active', mr: 1, my: 0.5}} />
          <TextField
            id="mid"
            label="mid"
            variant="standard"
            type={'number'}
            onChange={(e) => {
              setMid(e.target.value);
            }}
          />
        </Box>
        <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
          <AccountCircle sx={{color: 'action.active', mr: 1, my: 0.5}} />
          <TextField
            id="slot"
            label="슬롯"
            variant="standard"
            type={'number'}
            onChange={(e) => {
              setSlot(e.target.value);
            }}
          />
        </Box>
        <Box sx={{display: 'flex', gap: 2, p: 2}}>
          기간
          <Calendar
            onChange={(date) => {
              console.log(date, 'FSDD');
              setWorkingDate(date);
            }}
            value={workingDate}
            selectRange={true}
          />
        </Box>

        <Button variant="contained" onClick={insertProduct}>
          상품등록
        </Button>
      </Box>
    </Dialog>
  );
};

export default PopupComponent;
