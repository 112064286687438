import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import React from 'react';

export default function AlertDialog(props) {
  const {title, contents, open, setOpen, action} = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">크리 부스트</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{contents}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>닫기</Button>
          <Button onClick={async () => await action()} autoFocus>
            {title}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
