import React, {useState, useEffect} from 'react';
import DashboardCountComponent from '../../components/DashboardCountComponent';
import Box from '@mui/material/Box';
import {CurlResponse} from '../../util/type/CurlResponse';
import {API_BASE_URL, curl} from '../../util/RestApi';

const CountComponent = () => {
  const [activeCount, setActiveCount] = useState(0);
  const [sumSlot, setSumSlot] = useState(0);

  useEffect(() => {
    (async () => {
      const data: CurlResponse = await curl<CurlResponse>(API_BASE_URL + '/product/count/product', 'GET', null);
      setActiveCount(data.data);

      const slot: CurlResponse = await curl<CurlResponse>(API_BASE_URL + '/product/sum/slot', 'GET', null);
      setSumSlot(slot.data);
    })();
  }, []);

  return (
    <>
      <Box sx={{width: '100%', flexDirection: 'row', display: 'flex', gap: 1, marginBottom: '8px'}}>
        {/* <DashboardCountComponent label={'상품갯수'} value={activeCount} /> */}
        {/* <DashboardCountComponent label={'슬롯갯수'} value={sumSlot} /> */}
        {/* <DashboardCountComponent label={'비정상 상품'} value="122" />
        <DashboardCountComponent label={'만료된 상품'} value="122" /> */}
      </Box>
    </>
  );
};

export default CountComponent;
