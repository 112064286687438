import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MouseIcon from '@mui/icons-material/Mouse';
import {Button, IconButton, MenuItem, Select} from '@mui/material';
import {Link, Route, Routes, useNavigate} from 'react-router-dom';
import LoginScreen from '../login/LoginScreen';
import HomeScreen from '../home/HomeScreen';
import {GLOBAL_CUSTOM_TOKEN} from '../util/util';
import SettingScreen from '../settings/SettingScreen';
import SettingsIcon from '@mui/icons-material/Settings';
import {API_BASE_URL, curl} from '../util/RestApi';
import {CurlResponse} from '../util/type/CurlResponse';
import {useRecoilState} from 'recoil';
import {loginState} from '../atom/LoginState';
import {LoginStateType} from '../atom/type/LoginStateType';
import BarChartIcon from '@mui/icons-material/BarChart';
import SyncScreen from '../sync/SyncScreen';

const drawerWidth = 240;

export default function NavigationBar() {
  const [isLoggedIn, setIsLoggedIn] = React.useState<String>('W');
  const navigate = useNavigate();
  const [loginInfo, setLoginInfo] = useRecoilState<LoginStateType>(loginState);

  React.useEffect(() => {
    (async () => {
      const customToken = localStorage.getItem(GLOBAL_CUSTOM_TOKEN) ?? '';

      if (customToken === '') {
        setIsLoggedIn('N');
        return false;
      }

      try {
        const data: CurlResponse = await curl<CurlResponse>(API_BASE_URL + '/user/me', 'GET', null);
        console.log('data', data);
        if (data === undefined) {
          setLoginInfo({});
          setIsLoggedIn('N');
          localStorage.removeItem(GLOBAL_CUSTOM_TOKEN);
        } else {
          setLoginInfo(data.data);
          setIsLoggedIn('Y');
        }
        // customToken이 검증이 되면
      } catch (e) {
        if (e.response.status === 401) {
          setLoginInfo({});
          setIsLoggedIn('N');
          localStorage.removeItem(GLOBAL_CUSTOM_TOKEN);
        }
      }
    })();
  }, []);

  const handleLogout = () => {
    // 로그아웃 처리
    localStorage.removeItem(GLOBAL_CUSTOM_TOKEN);
    setIsLoggedIn('N');
    navigate('/');
  };

  if (isLoggedIn === 'W') {
    return null;
  }

  if (isLoggedIn === 'N') {
    return (
      <Routes>
        <Route path="/" element={<LoginScreen />} />
      </Routes>
    );
  }

  return (
    <div>
      <Box sx={{display: 'flex', flexGrow: 1}}>
        <CssBaseline />
        <AppBar position="fixed" sx={{zIndex: (theme) => theme.zIndex.drawer + 1}}>
          <Toolbar style={{justifyContent: 'space-between', width: '100%'}}>
            <Typography variant="h6" noWrap component="div">
              {`${loginInfo.username} (${loginInfo.email})`}
            </Typography>

            <div>
              <Button
                color="inherit"
                onClick={() => {
                  navigate('/settings');
                }}>
                설정
              </Button>
              <Button color="inherit" style={{marginLeft: 'auto'}} onClick={handleLogout}>
                Logout
              </Button>
            </div>
          </Toolbar>
        </AppBar>
        {/* <DrawerDiv /> */}
        <Box component="main" sx={{flexGrow: 1, p: 3}}>
          <Toolbar />
          <Routes>
            <Route path="/" element={<HomeScreen />} />
            <Route path="/stat" element={<HomeScreen />} />
            <Route path="/settings" element={<SettingScreen />} />
            <Route path="/sync" element={<SyncScreen />} />
          </Routes>
        </Box>
      </Box>
    </div>
  );
}

const DrawerDiv = () => {
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {width: drawerWidth, boxSizing: 'border-box'},
      }}>
      <Toolbar />
      <Box sx={{overflow: 'auto'}}>
        <List>
          {/* <ListItem
                disablePadding
                onClick={() => {
                  navigate('/');
                }}>
                <ListItemButton>
                  <ListItemIcon>
                    <MouseIcon />
                  </ListItemIcon>
                  <ListItemText primary={'전체슬롯'} />
                </ListItemButton>
              </ListItem> */}
          {/* <ListItem
                disablePadding
                onClick={() => {
                  navigate('/stat');
                }}>
                <ListItemButton>
                  <ListItemIcon>
                    <BarChartIcon />
                  </ListItemIcon>
                  <ListItemText primary={'통계'} />
                </ListItemButton>
              </ListItem> */}
          {/* <ListItem
                disablePadding
                onClick={() => {
                  navigate('/settings');
                }}>
                <ListItemButton>
                  <ListItemIcon>
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText primary={'설정'} />
                </ListItemButton>
              </ListItem> */}
          {/* <ListItem
                disablePadding
                onClick={() => {
                  navigate('/sync');
                }}>
                <ListItemButton>
                  <ListItemIcon>
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText primary={'동기화'} />
                </ListItemButton>
              </ListItem> */}
        </List>
        <Divider />
      </Box>
    </Drawer>
  );
};
