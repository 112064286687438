import axios, {AxiosResponse} from 'axios';
import {GLOBAL_CUSTOM_TOKEN} from './util';

// const API_BASE_URL: string = 'http://localhost:48963';
const API_BASE_URL: string = 'https://heroranking.kro.kr:48963/';

async function curl<CurlResponse>(url: string, method: string, data?: any): Promise<CurlResponse> {
  const customToken = localStorage.getItem(GLOBAL_CUSTOM_TOKEN) ?? '';
  const header = {
    // 'Content-Type': 'application/json',
    Authorization: `Bearer ${customToken}`,
  };

  if (customToken === '') {
    delete header['Authorization'];
  }

  try {
    const response = await axios({
      url: url,
      method: method,
      data: data,
      headers: header,
    });

    return response.data as CurlResponse;
  } catch (e) {
    if (e.response.status === 401) {
      alert('로그인이 만료되었습니다.');
    }
  }

  // return response.data as CurlResponse;
}

export {curl, API_BASE_URL};
