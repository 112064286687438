import {Button} from '@mui/material';
import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';

export default function OptionComponent({onEdit, onDelete, isAdmin}) {
  return (
    <>
      <Button variant="contained" color="success" size="small" onClick={onEdit}>
        <EditIcon />
      </Button>
      {isAdmin === 'ROLE_ADMIN' && (
        <Button variant="contained" color="error" size="small" onClick={onDelete} style={{marginLeft: '16px'}}>
          <DeleteIcon />
        </Button>
      )}
    </>
  );
}
OptionComponent.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};
